import { atom, selector } from 'recoil';
import { IOfferDetailRes, TAuthState, TScreenState, IDyOfferDetailRes } from '../types';

export const appState = atom({
  key: 'applicationState', // unique ID (with respect to other atoms/selectors)
  default: null // default value (aka initial value)
});

export const commonDataState = atom({
  key: 'commonDataState',
  default: null
});

export const userAgeState = atom<number | null>({
  key: 'userAge',
  default: null
});

// TODO: Check this (number -> string)
export const userPhoneNumberState = atom<string | null>({
  key: 'userPhoneNumber',
  default: null
});

interface IUserInput {
  age?: number;
  phoneNumber?: string;
}

export const userInputState = atom<IUserInput | null>({
  key: 'userInput',
  default: null
});

export const offerState = atom<IDyOfferDetailRes | null>({
  key: 'offer',
  default: null
});

export const authState = atom<TAuthState>({
  key: 'authState',
  default: 'UNVERIFIED' // pending, verified
});

export const recaptchaState = selector({
  key: 'recaptchaState',
  get: ({ get }) => {
    const appStateValue = get(appState);
    return appStateValue?.recaptcha;
  }
});

export const screenState = selector<TScreenState>({
  key: 'screenState',
  get: ({ get }) => {
    const currentAuthState = get(authState);
    const currentOffer = get(offerState);
    const isOldEnough = get(ageGateSelector);
    const ageGateRequired = currentOffer?.offer?.properties?.ageGateRequired === true;
    const offerStatus = currentOffer?.offer?.offerStatus;

    if (offerStatus === 'redeemed') {
      return 'ERROR';
    }
    if (currentOffer?.contextAttributes?.isUserLinked) {
      return 'AUTH_PHONE';
    }
    if (ageGateRequired && !isOldEnough) {
      return 'AGE_GATE';
    } else if (isOldEnough || !ageGateRequired) {
      if (currentAuthState === 'PENDING') {
        return 'AUTH_OTP';
      } else if (currentAuthState === 'VERIFIED') {
        return 'SMS_LANDING';
      } else if (offerStatus === 'inactive') {
        return 'ERROR';
      } else {
        return 'AUTH_PHONE';
      }
    }
    return 'AUTH_PHONE';
  }
  // set: ({ set, get }, newValue) => set(screenState, newValue)
});

export const ageGateSelector = selector<boolean>({
  key: 'ageGateSelector',
  get: ({ get }) => {
    const userAgeValue = get(userAgeState);
    return !!userAgeValue && userAgeValue >= 21; // false;
  }
});

export const reqValidityState = atom<string | null>({
  key: 'reqValidityState',
  default: null
});
