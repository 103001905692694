import React from 'react';
import { Button } from './button';
import { merge } from '../../utils';
import { Interpolation } from '@emotion/styled';

const iconButtonStyles = {
  width: 8,
  height: 8,
  padding: 0,
  border: '1px solid transparent',
  borderRadius: '50%'
};

type TResponsiveSizes = 'small' | 'medium' | 'large' | number;
type AlphaNum = number | string;

interface IIconButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  size?: TResponsiveSizes[] | TResponsiveSizes;
  variant?: 'primary' | 'secondary' | 'link' | 'ghost' | 'icon';
  fullWidth?: boolean | boolean[];
  margin?: AlphaNum | AlphaNum[];
  marginX?: AlphaNum | AlphaNum[];
  marginY?: AlphaNum | AlphaNum[];
  marginTop?: AlphaNum | AlphaNum[];
  marginRight?: AlphaNum | AlphaNum[];
  marginBottom?: AlphaNum | AlphaNum[];
  marginLeft?: AlphaNum | AlphaNum[];
  as?: string;
  component?: string;
  ref?: React.Ref<unknown>;
  css?: Interpolation<any>;
}

const IconButton: React.FC<IIconButtonProps> = React.forwardRef(function IconButton({ fullWidth, css, ...props }, ref) {
  let fullWidthStyles: any = {};
  if (Array.isArray(fullWidth)) {
    fullWidthStyles.borderRadius = fullWidth.map((fw) => (fw && '0') || iconButtonStyles.borderRadius);
    fullWidthStyles.width = fullWidth.map((fw) => (fw && '100%') || iconButtonStyles.width);
    fullWidthStyles.height = fullWidth.map((fw) => (fw && 'auto') || iconButtonStyles.height);
    fullWidthStyles.padding = fullWidth.map((fw) => (fw && 2) || iconButtonStyles.padding);
  } else if (fullWidth) {
    fullWidthStyles.borderRadius = 'none';
    fullWidthStyles.width = '100%';
    fullWidthStyles.height = 'auto';
    fullWidthStyles.padding = '2';
  }

  return <Button ref={ref} component="IconButton" css={merge(css, iconButtonStyles, fullWidthStyles)} {...props} />;
});

export { IconButton };
