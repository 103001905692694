export const styles = {
  searchInput: {
    boxSizing: 'border-box !important',
    lineHeight: 1, // trust the input height
    width: '100%',
    border: '1px solid',
    borderColor: 'primary',
    borderRadius: 0,
    ':disabled': {
      opacity: 0.5,
      cursor: 'not-allowed'
    }
  }
};
