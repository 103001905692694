import deepmerge from 'deepmerge';

export const mergeTwo = (a: any = {}, b: any = {}) => {
  // remove undefined values before merge
  Object.keys(a).forEach((key: any) => a[key] == undefined && delete a[key]);
  Object.keys(b).forEach((key: any) => b[key] == undefined && delete b[key]);

  return deepmerge(a, b);
};

export const merge = (...objects: any) => {
  return objects.reduce(function (merged: any, current: any) {
    return mergeTwo(merged, current);
  }, {});
};
