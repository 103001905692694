import React from 'react';

export const AddRoundIcon = ({ width = '24', height = '24', fill = 'currentColor', transform = 'rotate(0)' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 46 46">
    <g style={{ transform }} transform-origin="center">
      <g transform="translate(2 2)" stroke={fill} strokeMiterlimit="10" strokeWidth="2" fill="none">
        <ellipse cx="21" cy="20.806" rx="21" ry="20.806" stroke="none" />
        <ellipse cx="21" cy="20.806" rx="22" ry="21.806" />
      </g>
      <path fill={fill} d="M21 14h4v18h-4z" />
      <path fill={fill} d="M32 21v4H14v-4z" />
    </g>
  </svg>
);

export const CrossRoundIcon = (props) => (
  <AddRoundIcon component="CrossRoundIcon" transform="rotate(45deg)" {...props} />
);
