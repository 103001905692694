import delve from 'dlv';
import React from 'react';
import { Interpolation } from '@emotion/react';
import { Element } from '../../primitives';
import { styles } from './heading.styles';
import { merge } from '../../utils';

type AlphaNum = number | string;

interface IHeadingProps extends React.HtmlHTMLAttributes<HTMLHeadingElement> {
  [key: string]: any;
  variant?: 'default' | 'heading' | 'body' | 'subtle' | 'subtleDark' | 'danger';
  size?: AlphaNum | AlphaNum[];
  color?: string;
  margin?: AlphaNum | AlphaNum[];
  marginX?: AlphaNum | AlphaNum[];
  marginY?: AlphaNum | AlphaNum[];
  marginTop?: AlphaNum | AlphaNum[];
  marginRight?: AlphaNum | AlphaNum[];
  marginBottom?: AlphaNum | AlphaNum[];
  marginLeft?: AlphaNum | AlphaNum[];
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  ref?: React.Ref<unknown>;
  css?: Interpolation<any>;
}

export const Heading: React.FC<IHeadingProps> = React.forwardRef(function Heading({ size, css, ...props }, ref) {
  return (
    <Element
      ref={ref}
      as="h1"
      component="Heading"
      css={merge(styles.Heading, { fontSize: getWithFallback(`fontSizes.Heading.${size}`, size) }, css)}
      {...props}
    />
  );
});

const getWithFallback = (value, fallback) => (theme) => {
  const valueIfDefined = delve(theme, value);
  return valueIfDefined || fallback;
};
