import React from 'react';
import { Element } from '../../primitives';
import { merge } from '../../utils';
import { Interpolation } from '@emotion/react';

// TODO: prefixed version can be removed after upgrading to Emotion 11
const createGap = (direction, gap) => {
  if (direction === 'vertical') {
    return {
      marginBottom: gap,
      marginRight: 0
    };
  }

  return {
    marginBottom: 0,
    marginRight: gap
  };
};

type StackDirection = 'vertical' | 'horizontal';
type AlphaNum = number | string;

interface IStsackProps extends React.HTMLAttributes<HTMLDivElement> {
  [key: string]: any;
  inline?: boolean | boolean[];
  align?: string | string[];
  justify?: string | string[];
  direction?: StackDirection | StackDirection[];
  gap?: AlphaNum | AlphaNum[];
  margin?: AlphaNum | AlphaNum[];
  marginX?: AlphaNum | AlphaNum[];
  marginY?: AlphaNum | AlphaNum[];
  marginTop?: AlphaNum | AlphaNum[];
  marginRight?: AlphaNum | AlphaNum[];
  marginBottom?: AlphaNum | AlphaNum[];
  marginLeft?: AlphaNum | AlphaNum[];
  as?: string | React.ElementType | keyof JSX.IntrinsicElements;
  component?: string;
  ref?: React.Ref<unknown>;
  css?: Interpolation<any>;
}

export const Stack: React.FC<IStsackProps> = React.forwardRef(function Stack(
  { inline, justify, align, direction, gap, css, ...props },
  ref
) {
  const styles = {
    display: inline ? 'inline-flex' : 'flex',
    // width: '100%', // causes weirdness in nested avatar. todo: debug
    justifyContent: justify,
    alignItems: align
  };

  if (Array.isArray(direction)) {
    // @ts-ignore
    styles.flexDirection = direction.map((d) => (d === 'vertical' ? 'column' : 'row'));

    styles['> *:not(:last-child)'] = direction.map((d) => createGap(d, gap));
  } else {
    // @ts-ignore
    styles.flexDirection = direction === 'vertical' ? 'column' : 'row';
    styles['> *:not(:last-child)'] = createGap(direction, gap);
  }

  return <Element ref={ref} as="div" component="Stack" css={merge(styles, css)} {...props} />;
});

Stack.defaultProps = {
  direction: 'horizontal',
  inline: false
};
