import React from 'react';
import { Interpolation } from '@emotion/react';
import { useId } from '@reach/auto-id';
import { Text } from '../text';
import { Stack } from '../stack';
import { Element } from '../../primitives';
import { styles } from './checkbox.styles';
import { merge } from '../../utils';

type AlphaNum = number | string;

interface ICheckboxProps extends React.HtmlHTMLAttributes<HTMLInputElement> {
  [key: string]: any;
  label: string;
  size?: AlphaNum | AlphaNum[];
  color?: string;
  margin?: AlphaNum | AlphaNum[];
  marginX?: AlphaNum | AlphaNum[];
  marginY?: AlphaNum | AlphaNum[];
  marginTop?: AlphaNum | AlphaNum[];
  marginRight?: AlphaNum | AlphaNum[];
  marginBottom?: AlphaNum | AlphaNum[];
  marginLeft?: AlphaNum | AlphaNum[];
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  ref?: React.Ref<unknown>;
  css?: Interpolation<any>;
}

export const Checkbox: React.FC<ICheckboxProps> = React.forwardRef(function Checkbox(
  { id, label, value = false, css, ...props },
  ref
) {
  const inputId = useId(id);
  return (
    <Stack align="center" gap={2}>
      <Element
        as="input"
        type="checkbox"
        component="Checkbox"
        id={inputId}
        value={value}
        {...props}
        css={merge(styles.Checkbox, css)}
      />
      <Text as="label" htmlFor={inputId}>
        {label}
      </Text>
    </Stack>
  );
});
