import React from 'react';
import { useRecoilState } from 'recoil';
import { useClient } from 'urql';

import loadable from '@loadable/component';
import { getUrlParameter } from '@quotient/riq-helpers';
import { Element, Loader } from '@quotient/riq-react-ui';
import { RouteComponentProps, Router } from '@reach/router';

import { appState } from './AppState';
import Footer from './components/Footer';
import Page from './components/Page';
import NotFound from './containers/NotFound/NotFound';

const SmsCampaignNew = loadable(() => import(/* webpackPrefetch: true */ './containers/SmsCampaignNew'), {
  fallback: <Loader css={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
});

const SmsCampaign = loadable(() => import(/* webpackPrefetch: true */ './containers/SmsCampaign'), {
  fallback: <Loader css={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
});

const DynamicSmsCampaignNew = loadable(() => import(/* webpackPrefetch: true */ './containers/DynamicSmsCampaignNew'), {
  fallback: <Loader css={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
});

const DynamicSmsCampaign = loadable(() => import(/* webpackPrefetch: true */ './containers/DynamicSmsCampaign'), {
  fallback: <Loader css={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
});

const getCommonDataQuery = `query {
  getCommonData {
    recaptcha { publicKey { v2 v3 } }
  }
}`;

const Routes: React.FC<RouteComponentProps> = function Routes(params) {
  const [, setAppState] = useRecoilState(appState);
  const client = useClient();
  const abtest = getUrlParameter('abtest');

  React.useEffect(() => {
    client
      .query(getCommonDataQuery)
      .toPromise()
      .then(({ data: { getCommonData = null } = {} }) => {
        if (getCommonData) {
          setAppState(getCommonData);
        }
      })
      .catch((err) => {
        console.log('common data api error');
      });
  }, []);

  return (
    <Element
      as="div"
      component="container"
      css={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        maxHeight: '100vh',
        position: 'relative'
      }}
    >
      <React.Suspense fallback={<Loader />}>
        <Router
          basepath="/"
          style={{ display: 'flex', flex: '1 1 auto', margin: 0, position: 'relative', overflow: 'hidden' }}
        >
          <Page path="/">
            {abtest === 'true' ? <SmsCampaignNew path="/sms-campaign" /> : <SmsCampaign path="/sms-campaign" />}
            {abtest === 'true' ? (
              <DynamicSmsCampaignNew path="/sms-campaign-dynamic" />
            ) : (
              <DynamicSmsCampaign path="/sms-campaign-dynamic" />
            )}
            <NotFound default />
          </Page>
        </Router>

        <Footer />
      </React.Suspense>
    </Element>
  );
};

export default Routes;
