import React from 'react';
import { Stack, Text, Link, Element } from '@quotient/riq-react-ui';

function Footer() {
  return (
    <Element as="footer" css={{ display: 'flex', textAlign: 'center', justifyContent: 'center', alignItems: 'center' }}>
      <Stack
        align="center"
        css={{
          background: 'grays.200',
          padding: 2,
          height: '48px',
          width: '100%',
          flexShrink: 0
        }}
      >
        <Text size={3} align="center" css={{ paddingX: 2, width: '100%' }}>
          <Link color="grays.700" href="https://www.quotient.com/terms-of-use/">
            Terms of Use
          </Link>{' '}
          |{' '}
          <Link color="grays.700" href="https://www.quotient.com/privacy-portal/" css={{ whiteSpace: 'nowrap' }}>
            Do Not Sell My Personal Information
          </Link>{' '}
          |{' '}
          <Link color="grays.700" href="https://www.quotient.com/privacy-policy/">
            Privacy Policy
          </Link>{' '}
          |{' '}
          <Link color="grays.700" href="https://www.quotient.com/supplemental-california-privacy-notice/">
            CA Privacy Notice
          </Link>
        </Text>
      </Stack>
    </Element>
  );
}

export default Footer;
