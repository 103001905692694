import { useState, useRef, useEffect } from 'react';

const useSizeElement = () => {
  const elRef = useRef<HTMLElement>(null);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    if (elRef.current) setWidth(elRef.current.clientWidth);
  }, [elRef.current]);

  return { width, elRef };
};

export default useSizeElement;
