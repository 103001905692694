import React from 'react';
import { Element } from '../../primitives';
import { styles } from './input.styles';
import { merge } from '../../utils';
import { Input } from './input';

type AlphaNum = number | string;

interface IInputGroup extends React.HTMLAttributes<HTMLElement> {
  [key: string]: any;
  css?: any;
  size?: any;
  margin?: AlphaNum | AlphaNum[];
  marginX?: AlphaNum | AlphaNum[];
  marginY?: AlphaNum | AlphaNum[];
  marginTop?: AlphaNum | AlphaNum[];
  marginRight?: AlphaNum | AlphaNum[];
  marginBottom?: AlphaNum | AlphaNum[];
  marginLeft?: AlphaNum | AlphaNum[];
  as?: string | React.ElementType | keyof JSX.IntrinsicElements;
  component?: string;
  ref?: React.Ref<unknown>;
}

export const InputGroup: React.FC<IInputGroup> = ({ children, css, ...props }) => (
  <Element component="InputGroup" css={merge(styles.InputGroup, css)} {...props}>
    {children}
  </Element>
);

export const InputAddon: React.FC<IInputGroup> = ({ as = 'span', css, children, ...props }) => (
  <Element as={as} component="InputAddon" css={merge(styles.InputAddon, css)} {...props}>
    {children}
  </Element>
);

export const InputField = (props) => <Input component="InputField" {...props} />;
