import React from 'react';
import { Element } from '../../primitives';
import { styles } from './img.styles';
import { merge } from '../../utils';

type AlphaNum = number | string;

interface IImgProps {
  [key: string]: any;
  alt: string;
  css: any;
  src: string;
  margin?: AlphaNum | AlphaNum[];
  marginX?: AlphaNum | AlphaNum[];
  marginY?: AlphaNum | AlphaNum[];
  marginTop?: AlphaNum | AlphaNum[];
  marginRight?: AlphaNum | AlphaNum[];
  marginBottom?: AlphaNum | AlphaNum[];
  marginLeft?: AlphaNum | AlphaNum[];
  as?: any;
  component?: string;
  ref?: React.Ref<unknown>;
}

export const Image = React.forwardRef(function Img({ css, ...props }: any, ref) {
  return (
    <Element ref={ref} css={merge(styles.Img, css)}>
      <Element as="img" component="Img" css={{ maxHeight: '100%', height: 'inherit' }} {...props} />
    </Element>
  );
});

Image.defaultProps = {};
