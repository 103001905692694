import { useState, useLayoutEffect } from 'react';

interface Size {
  width: number;
  height: number;
}

export function useWindowSize(): Size {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState<Size>({
    width: -1,
    height: -1
  });
  useLayoutEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    }
    // Add event listener
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

// return boolean value saying if screen is of Mobile or not
export function useIsMobileScreen(breakPoint = 576): boolean {
  const size = useWindowSize();
  return size.width < breakPoint;
}
