import React, { useState, useEffect } from 'react';
import Portal from '@reach/portal';
import { Element } from '../../primitives';

// @TODO: handle keyboard navigations & mouse movements & selection
type ButtonRef = React.RefObject<null | HTMLButtonElement>;
type MenuListRef = React.RefObject<null | HTMLElement>;

interface InternalMenuContextValue {
  setOpen: (value: boolean) => void;
  open: boolean;
  menuListRef: MenuListRef;
  buttonRef: ButtonRef;
}

const MenuContext = React.createContext<InternalMenuContextValue>({} as InternalMenuContextValue);
const MenuProvider = MenuContext.Provider;

interface IMenuProp extends React.HTMLAttributes<HTMLDivElement> {
  updateMenuState: ({ open: boolean }) => void;
}
export const Menu: React.FC<IMenuProp> = ({ updateMenuState, onSelect, children, ...props }) => {
  const [open, setOpen] = useState(false);
  const menuListRef = React.useRef<HTMLElement>(null);
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const context = { setOpen, open, menuListRef, buttonRef };

  const closeMenu = () => setOpen(false);

  useEffect(() => {
    updateMenuState && updateMenuState({ open });
  }, [open]);

  useEffect(() => {
    const listener = (event: MouseEvent) => {
      if (menuListRef.current?.contains(event?.target as Node)) return;
      else if (buttonRef.current?.contains(event?.target as Node)) return;
      closeMenu();
    };

    document.addEventListener('mousedown', listener);
    return () => document.removeEventListener('mousedown', listener);
  }, [menuListRef, buttonRef]);

  return <MenuProvider value={context}>{children}</MenuProvider>;
};

export type MenuButtonProps = {
  as?: string | React.ElementType | keyof JSX.IntrinsicElements;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

// @TODO: handle forwared ref along with internal ref
export const MenuButton: React.FC<MenuButtonProps> = React.forwardRef(function MenuButton(
  { as: Comp = 'button', ...props },
  forwardedRef
) {
  const { open, setOpen, menuListRef, buttonRef } = React.useContext(MenuContext);

  const toggleMenu = () => {
    setOpen(!open);

    if (!open) {
      menuListRef?.current?.focus();
    } else {
      buttonRef?.current?.focus();
    }
  };

  const checkKey = (e: React.KeyboardEvent) => {
    if (e.key === 'Escape') return open && setOpen(false);
    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') return !open && setOpen(true);
  };

  return (
    <Comp
      aria-expanded={open ? true : undefined}
      aria-haspopup
      onKeyDown={checkKey}
      onClick={toggleMenu}
      ref={buttonRef}
      type="button"
      {...props}
    />
  );
});

// @TODO: Take out Overlay component seperately
export type MenuOverlayProps = {
  positions?: {
    top?: string | number;
    left?: string | number;
  };
  fixed?: boolean;
  show?: boolean;
  as?: string | React.ElementType | keyof JSX.IntrinsicElements;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export const MenuOverlay: React.FC<MenuOverlayProps> = React.forwardRef(function MenuOverlay(
  { positions, fixed, show, ...props },
  ref
) {
  // const { open, setOpen, menuListRef, buttonRef } = React.useContext(MenuContext);
  // const toggleMenu = () => setOpen(!open);
  if (!show) return null;

  return (
    <Portal>
      <Element
        css={{
          position: fixed ? 'fixed' : 'absolute',
          top: positions?.top ?? 0,
          // top: buttonRef?.current?.offsetTop + buttonRef?.current?.offsetHeight + 'px' || 0,
          width: '100vw',
          height: '100vh',
          background: 'rgba(33,33,33, 0.8 )',
          zIndex: 2
        }}
        {...props}
      />
    </Portal>
  );
});

// @TODO: takeout positioning as popover component
export type MenuListProps = {
  as?: string | React.ElementType | keyof JSX.IntrinsicElements;
} & React.HTMLAttributes<HTMLDivElement>;

export const MenuList: React.FC<MenuListProps> = React.forwardRef(function MenuList(
  { as: Comp = 'div', children, ...props },
  ref
) {
  const { open, menuListRef, buttonRef } = React.useContext(MenuContext);
  useEffect(() => {
    open && menuListRef.current?.focus();
  }, [menuListRef, open]);

  if (!open) return null;

  // @TODO: add aria-labelledby with menu Id
  return (
    <Portal>
      <Comp
        role="menu"
        tabIndex={-1}
        ref={menuListRef}
        style={{
          position: 'absolute',
          border: '1px solid #dedede',
          top: buttonRef?.current?.offsetTop + buttonRef?.current?.offsetHeight + 15 + 'px' || 0,
          left: buttonRef?.current?.offsetLeft - 128 + 'px' || 0,
          zIndex: 3,
          background: '#fff'
        }}
        {...props}
      >
        {children}
      </Comp>
    </Portal>
  );
});

type MenuItemProps = {
  as?: string | React.ElementType | keyof JSX.IntrinsicElements;
} & React.HTMLAttributes<HTMLDivElement>;

export const MenuItem: React.FC<MenuItemProps> = React.forwardRef(function MenuItem(
  { as: Comp = 'div', onClick, ...props },
  ref
) {
  let { open, setOpen } = React.useContext(MenuContext);
  const onClickWrapper = function (e) {
    onClick && onClick(e);
    setOpen(!open);
  };
  return <Comp onClick={onClickWrapper} role="menuitem" ref={ref} {...props} />;
});

export type MenuContextValue = {
  open: boolean;
};

export function useMenuButtonContext(): MenuContextValue {
  let { open } = React.useContext(MenuContext);
  return React.useMemo(() => ({ open }), [open]);
}
