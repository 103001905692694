import React from 'react';
import { Element } from '../../primitives';
import { styles } from './error.styles';
import { merge } from '../../utils';
import { Text } from '../text';

type AlphaNum = number | string;

interface IErrorProps extends React.HtmlHTMLAttributes<HTMLElement> {
  [key: string]: any;
  css?: any;
  margin?: AlphaNum | AlphaNum[];
  marginX?: AlphaNum | AlphaNum[];
  marginY?: AlphaNum | AlphaNum[];
  marginTop?: AlphaNum | AlphaNum[];
  marginRight?: AlphaNum | AlphaNum[];
  marginBottom?: AlphaNum | AlphaNum[];
  marginLeft?: AlphaNum | AlphaNum[];
  as?: string | React.ElementType | keyof JSX.IntrinsicElements;
  component?: string;
  ref?: React.Ref<unknown>;
}

export const Error: React.FC<IErrorProps> = React.forwardRef(function Error({ css, ...props }, ref) {
  return <Text ref={ref} variant="danger" css={merge(styles.Error, css)} {...props} />;
});

Error.defaultProps = {};
