export const styles = {
  List: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column'
  },
  ListItem: {
    // listStyle: 'none'
    // display: 'list-item'
  }
};
