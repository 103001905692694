import React, { TextareaHTMLAttributes } from 'react';
import { styles } from './textarea.styles';
import { merge } from '../../utils';
import { Interpolation } from '@emotion/react';
import { Element } from '../../primitives';

type TextareaProps = {
  [key: string]: any;
  css?: Interpolation<any>;
  rows?: number | number[];
} & React.TextareaHTMLAttributes<HTMLTextAreaElement>;

const Textarea: React.FC<TextareaProps> = React.forwardRef(function Textarea({ rows = 4, css, ...props }, ref) {
  return (
    <Element ref={ref} as="textarea" rows={rows} css={merge(styles.Textarea, css)} component="Textarea" {...props} />
  );
});

export { Textarea };
