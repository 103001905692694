import React from 'react';
import { Element } from '../../primitives';
import { styles } from './button.styles';
import { merge } from '../../utils';

type TResponsiveSizes = 'small' | 'medium' | 'large' | number;
type AlphaNum = number | string;

interface IButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  [key: string]: any;
  size?: TResponsiveSizes[] | TResponsiveSizes;
  variant?: 'primary' | 'primary-noeffect' | 'secondary' | 'link' | 'ghost' | 'icon' | 'plain' | 'subtle' | string;
  fullWidth?: boolean | boolean[];
  margin?: AlphaNum | AlphaNum[];
  marginX?: AlphaNum | AlphaNum[];
  marginY?: AlphaNum | AlphaNum[];
  marginTop?: AlphaNum | AlphaNum[];
  marginRight?: AlphaNum | AlphaNum[];
  marginBottom?: AlphaNum | AlphaNum[];
  marginLeft?: AlphaNum | AlphaNum[];
  as?: string | React.ElementType | keyof JSX.IntrinsicElements;
  component?: string;
  ref?: React.Ref<unknown>;
}

/** Description of a button */
export const Button: React.FC<IButtonProps> = React.forwardRef(function Button({ fullWidth, css, ...props }, ref) {
  let width = fullWidth ? '100%' : 'auto';

  return <Element ref={ref} as="button" component="Button" css={merge(styles.Button, { width }, css)} {...props} />;
});

Button.defaultProps = {
  type: 'button',
  size: 'medium'
};
