export const styles = {
  DialogContent: {
    position: ['fixed', 'relative'],
    width: ['100vw', '60vw', '45vw'],
    minHeight: ['100vh', '30vh'],
    outline: 'none',
    borderRadius: 0,
    maxHeight: '100vh',
    overflow: 'auto'
  },
  SidebarContent: {
    position: 'fixed',
    top: 0,
    right: 0,
    width: '350px',
    height: '100vh',
    transition: 'transform .5s ease-in-out',
    transform: 'translateX(100%)',
    '&.isOpen': {
      transform: 'translateX(0)'
    }
  },
  DialogOverlay: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    overflow: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 9999
  }
};
