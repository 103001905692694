export const styles: any = {
  SeperatorText: {
    display: 'flex',
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#444444',
    margin: 0,
    '&:before, &:after': {
      color: 'white',
      content: '""',
      flex: '1',
      borderBottom: '1px solid #cdcdcd',
      margin: 'auto',
      boxShadow: '0 -1px'
    }
  }
};
