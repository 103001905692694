import React from 'react';
import { Element } from '../../primitives';
import { styles } from './input.styles';
import { merge } from '../../utils';

type AlphaNum = number | string;

interface IInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  [key: string]: any;
  invalid?: boolean;
  css?: any;
  type?: any;
  size?: any;
  margin?: AlphaNum | AlphaNum[];
  marginX?: AlphaNum | AlphaNum[];
  marginY?: AlphaNum | AlphaNum[];
  marginTop?: AlphaNum | AlphaNum[];
  marginRight?: AlphaNum | AlphaNum[];
  marginBottom?: AlphaNum | AlphaNum[];
  marginLeft?: AlphaNum | AlphaNum[];
  as?: string | React.ElementType | keyof JSX.IntrinsicElements;
  component?: string;
  ref?: React.Ref<unknown>;
}

/** Description of an input */
export const Input: React.FC<IInputProps> = React.forwardRef(function Input({ invalid, css, ...props }, ref) {
  return (
    <Element ref={ref} as="input" component="Input" aria-invalid={invalid} css={merge(styles.Input, css)} {...props} />
  );
});

Input.defaultProps = {
  type: 'text'
};
