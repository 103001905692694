import React from 'react';
import ReactInputMask from 'react-input-mask';
import { Input } from './input';

const InputMask = React.forwardRef(function InputMask({ value, onChange, size, css, ...props }: any, ref) {
  return (
    <ReactInputMask value={value} {...props}>
      <Input ref={ref} size={size} css={css} {...props} />
    </ReactInputMask>
  );
});

export { InputMask };
