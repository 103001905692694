import React from 'react';
import { Element } from '../../primitives';
import { merge } from '../../utils';
import { Interpolation } from '@emotion/react';

type AlphaNum = number | string;

interface ITextProps extends React.HtmlHTMLAttributes<HTMLSpanElement> {
  [key: string]: any;
  variant?: 'default' | 'heading' | 'body' | 'subtle' | 'subtleDark' | 'danger';
  size?: AlphaNum | AlphaNum[];
  color?: string;
  align?: 'left' | 'right' | 'center' | 'justify' | 'inherit' | 'initial';
  weight?: string;
  block?: boolean;
  maxWidth?: string;
  fullWidth?: boolean;
  margin?: AlphaNum | AlphaNum[];
  marginX?: AlphaNum | AlphaNum[];
  marginY?: AlphaNum | AlphaNum[];
  marginTop?: AlphaNum | AlphaNum[];
  marginRight?: AlphaNum | AlphaNum[];
  marginBottom?: AlphaNum | AlphaNum[];
  marginLeft?: AlphaNum | AlphaNum[];
  as?: string | React.ElementType | keyof JSX.IntrinsicElements;
  component?: string;
  ref?: React.Ref<unknown>;
  css?: Interpolation<any>;
}

export const Text: React.FC<ITextProps> = React.forwardRef(function Text(
  { size, color, align, weight, block, css, maxWidth, fullWidth, ...props },
  ref
) {
  let width = 'auto';
  if (fullWidth) width = '100%';

  return (
    <Element
      ref={ref}
      as="span"
      component="Text"
      css={merge(
        {
          fontSize: size,
          textAlign: align,
          fontWeight: weight,
          color: color,
          display: block || props.marginBottom ? 'block' : 'inline',
          width,
          maxWidth: maxWidth,
          ...(maxWidth ? overflowStyles : {})
        },
        css
      )}
      {...props}
    />
  );
});

const overflowStyles = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap'
};
