export const styles = {
  Input: {
    boxSizing: 'border-box',
    lineHeight: 1, // trust the input height
    width: '100%',
    border: '1px solid',
    ':disabled': {
      opacity: 0.5,
      cursor: 'not-allowed'
    }
  },
  InputGroup: {
    display: 'flex',
    width: '100%',
    lineHeight: 1,
    '&:first-child': { borderRadius: '8px 0 0 8px' },
    '&:last-child': { borderRadius: '0 8px 8px 0' },
    Input: {
      '&:first-child': { borderRadius: '8px 0 0 8px' },
      '&:last-child': { borderRadius: '0 8px 8px 0' },
      '&:not(:last-child)': { borderRight: '0' },
      '&:not(:first-child)': { borderLeft: '0' }
    }
  },
  InputField: {
    flex: 1,
    boxSizing: 'border-box',
    lineHeight: 1, // trust the input height
    border: '1px solid',
    ':disabled': {
      opacity: 0.5,
      cursor: 'not-allowed'
    }
  },
  InputAddon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 0
  }
};
