import React, { ReactNode, HTMLAttributes, useState } from 'react';
import { Element } from '../../primitives';
import { styles } from './carousel.styles';
import { Stack } from '../stack';
import useSizeElement from './useSizeElement';
import useSliding from './useSlidding';
import CarouselContext from './context';
import { IconButton } from '../button';
import { ArrowRightIcon, ArrowLeftIcon } from '../../icons/Arrow';

type AlphaNum = number | string;

interface ICarouselProps extends HTMLAttributes<HTMLDivElement> {
  [key: string]: any;
  heading?: string;
  desc?: string;
  arrowButtons?: boolean;
  margin?: AlphaNum | AlphaNum[];
  marginX?: AlphaNum | AlphaNum[];
  marginY?: AlphaNum | AlphaNum[];
  marginTop?: AlphaNum | AlphaNum[];
  marginRight?: AlphaNum | AlphaNum[];
  marginBottom?: AlphaNum | AlphaNum[];
  marginLeft?: AlphaNum | AlphaNum[];
  ref?: React.Ref<unknown>;
  children?: ReactNode;
}

export const Carousel: React.FC<ICarouselProps> = React.forwardRef(function Carousel(
  { heading, desc, arrowButtons = true, children, ...props },
  ref
) {
  const { width, elRef } = useSizeElement();
  const { handlePrev, handleNext, slideProps, containerRef, hasNext, hasPrev } = useSliding(
    width,
    React.Children.count(children)
  );
  const contextValue = { elRef };

  return (
    <CarouselContext.Provider value={contextValue}>
      <Element ref={ref} css={styles.CarouselWrapper}>
        <Element ref={containerRef} css={styles.Carousel} {...slideProps}>
          {React.Children.map(children, (child: React.ReactElement) => (
            <CarouselItem itemRef={elRef}>{child}</CarouselItem>
          ))}
        </Element>

        {hasPrev && (
          <IconButton
            css={{ position: 'absolute', top: '50%', left: '10px', transform: 'translate(0, -50%)' }}
            onClick={handlePrev}
          >
            <ArrowLeftIcon />
          </IconButton>
        )}
        {hasNext && (
          <IconButton
            css={{ position: 'absolute', top: '50%', right: '10px', transform: 'translate(0, -50%)' }}
            onClick={handleNext}
          >
            <ArrowRightIcon />
          </IconButton>
        )}
      </Element>
    </CarouselContext.Provider>
  );
});

interface ICarouselItemProps {
  itemRef?: React.MutableRefObject<HTMLElement | null>;
}

const CarouselItem: React.FC<ICarouselItemProps> = ({ itemRef, ...props }) => {
  return <Element ref={itemRef} component="CarouselItem" marginX={1} css={styles.CarouselItem} {...props} />;
};
