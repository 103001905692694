import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import './styles/index.css';

declare global {
  interface Window {
    cdnBasePath: any;
  }
}

if (window.cdnBasePath) {
  __webpack_public_path__ = window.cdnBasePath;
}

ReactDOM.hydrate(<App />, document.getElementById('root'));
module.hot && module.hot.accept();
