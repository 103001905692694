import React from 'react';
import { Element } from '../../primitives';
import { styles } from './loader.styles';
import { merge } from '../../utils';

type AlphaNum = number | string;

interface ILoaderProps extends React.HtmlHTMLAttributes<HTMLSpanElement> {
  [key: string]: any;
  css?: any;
  margin?: AlphaNum | AlphaNum[];
  marginX?: AlphaNum | AlphaNum[];
  marginY?: AlphaNum | AlphaNum[];
  marginTop?: AlphaNum | AlphaNum[];
  marginRight?: AlphaNum | AlphaNum[];
  marginBottom?: AlphaNum | AlphaNum[];
  marginLeft?: AlphaNum | AlphaNum[];
  as?: string | React.ElementType | keyof JSX.IntrinsicElements;
  component?: string;
  ref?: React.Ref<unknown>;
}

export const Loader: React.FC<ILoaderProps> = React.forwardRef(function Loader({ css, ...props }, ref) {
  return <Element ref={ref} as="span" component="Loader" css={merge(styles.Loader, css)} {...props} />;
});

Loader.defaultProps = {};
