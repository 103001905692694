import 'regenerator-runtime/runtime';

import React, { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { RecoilRoot } from 'recoil';
import { Provider } from 'urql';

import { useUrqlClient } from '@quotient/riq-ql';
import { useSafeSetState } from '@quotient/riq-react-hooks';
import { Error, Loader, ThemeProvider } from '@quotient/riq-react-ui';

import Routes from './Routes';
import theme from './theme';
import { pushEventsToDataLayer } from './util/eventAnalytics';

function App() {
  const [appState, setAppState] = useSafeSetState({});
  const [urqlClient] = useUrqlClient();
  pushEventsToDataLayer();

  return (
    <Suspense
      fallback={() => (
        <Loader css={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
      )}
    >
      <Provider value={urqlClient}>
        <RecoilRoot>
          <ThemeProvider theme={theme}>
            <ErrorBoundary FallbackComponent={() => <Error />}>
              <Suspense
                fallback={
                  <Loader css={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
                }
              >
                <Routes />
              </Suspense>
            </ErrorBoundary>
          </ThemeProvider>
        </RecoilRoot>
      </Provider>
    </Suspense>
  );
}

export default App;
