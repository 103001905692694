import React from 'react';
import { Svg } from '../components/svg';

export const ArrowRightIcon = ({ width = '24', height = '24', transform = 'rotate(0)', ...props }) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 551 1024" {...props}>
    <path
      style={{ transformOrigin: '50% 50%' }}
      transform={transform}
      d="M105.56 985.817L553.53 512 105.56 38.183l-85.857 81.173 409.6 433.23v-81.172l-409.6 433.23 85.856 81.174z"
    />
  </Svg>
);

export const ArrowLeftIcon = (props) => <ArrowRightIcon component="ArrowLeftIcon" transform="rotate(180)" {...props} />;
export const ArrowTopIcon = (props) => <ArrowRightIcon component="ArrowTopIcon" transform="rotate(-90)" {...props} />;
export const ArrowBottomIcon = (props) => (
  <ArrowRightIcon component="ArrowBottomIcon" transform="rotate(90)" {...props} />
);
