import React from 'react';
import { Element } from '../../primitives';
import { merge } from '../../utils';
import { Input } from './input';

const adornmentStyles = {
  Adornment: {
    position: 'absolute',
    background: 'transparent',
    border: 'none'
  }
};

interface IAdornmentProps extends React.InputHTMLAttributes<HTMLInputElement> {
  before?: any;
  after?: any;
  size?: any;
  css?: any;
}

const Adornment: React.FC<IAdornmentProps> = ({ css, ...props }) => (
  <Element as="i" component="Adornment" css={merge(adornmentStyles.Adornment, css)} {...props} />
);

const adornmentButtonStyles = {
  Adornment: {
    position: 'absolute',
    // background: 'primary',
    border: 'none'
  }
};

const AdornmentButton: React.FC<IAdornmentProps> = ({ css, ...props }) => (
  <Element as="button" component="Adornment" css={merge(adornmentButtonStyles.Adornment, css)} {...props} />
);

export const InputWithAdornment: React.FC<IAdornmentProps> = ({ before, after, css, onSubmit, ...props }) => {
  return (
    <Element css={merge({ display: 'flex', flex: 1, alignItems: 'center', position: 'relative' }, css)}>
      {before && (
        <Adornment css={{ left: 0, padding: ['10px 2px 8px 12px'], borderRadius: '8px 0 0 8px' }}>{before}</Adornment>
      )}
      <Input {...props} css={{ paddingLeft: before ? 8 : 2, paddingRight: after ? 11 : 2 }} />
      {after && (
        <AdornmentButton
          onClick={onSubmit}
          css={{ right: 0, padding: '10px 12px 8px 8px', borderRadius: '0 8px 8px 0' }}
        >
          {after}
        </AdornmentButton>
      )}
    </Element>
  );
};
