import React from 'react';
import { DialogContent } from '@reach/dialog';
import Portal from '@reach/portal';
import { Interpolation } from '@emotion/react';

import { Element } from '../../primitives';
import { styles } from './dialog.styles';
import { merge } from '../../utils';
import { Button } from '../button';
import { CrossRoundIcon } from '../../icons';
import { Text } from '../text';

export type OverlayProps = {
  positions?: {
    top?: string | number;
    left?: string | number;
  };
  fixed?: boolean;
  isOpen?: boolean;
  onDismiss?: () => void;
  as?: string | React.ElementType | keyof JSX.IntrinsicElements;
  [key: string]: any;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const Overlay: React.FC<OverlayProps> = React.forwardRef(function Overlay(
  { positions, fixed = true, isOpen = true, css, onDismiss, children, ...props },
  ref
) {
  // const { open, setOpen, menuListRef, buttonRef } = React.useContext(MenuContext);
  // const toggleMenu = () => setOpen(!open);
  // if (!isOpen) return null;

  return (
    <Portal>
      <Element
        component="DialogOverlay"
        onClick={onDismiss}
        css={merge(
          styles.DialogOverlay,
          {
            position: fixed ? 'fixed' : 'absolute',
            top: positions?.top ?? 0,
            // top: buttonRef?.current?.offsetTop + buttonRef?.current?.offsetHeight + 'px' || 0,
            width: '100vw',
            height: '100vh',
            background: 'rgba(33,33,33, 0.8 )',
            zIndex: 10001,
            display: isOpen ? 'flex' : 'none'
          },
          css
        )}
        {...props}
      >
        {children}
      </Element>
    </Portal>
  );
});

export type DialogProps = {
  positions?: {
    top?: string | number;
    left?: string | number;
  };
  width?: string | number;
  height?: string | number;
  fixed?: boolean;
  isOpen?: boolean;
  as?: string | React.ElementType | keyof JSX.IntrinsicElements;
  [key: string]: any;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export const Dialog: React.FC<DialogProps> = React.forwardRef(function Dialog(
  { isOpen, onDismiss, css, children, closeIcon, ...props },
  ref
) {
  const handleKeyDown = (e: React.KeyboardEvent) => (e.keyCode === 27 || e.key === 'Escape') && onDismiss();

  return (
    <Overlay css={css} isOpen={isOpen} onKeyDown={handleKeyDown} onDismiss={onDismiss} {...props}>
      <Element
        ref={ref}
        as={DialogContent}
        component="DialogContent"
        onKeyDown={handleKeyDown}
        css={merge(styles.DialogContent, css)}
        {...props}
      >
        <CloseDialog onDismiss={onDismiss} closeIcon={closeIcon} />
        {children}
      </Element>
    </Overlay>
  );
});

export type CloseDialogProps = {
  onDismiss?: () => void;
  closeIcon?: JSX.Element;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const CloseDialog: React.FC<CloseDialogProps> = ({ onDismiss, closeIcon = <CrossRoundIcon /> }) => (
  <Button
    variant="icon"
    css={{ background: 'transparent', position: 'absolute', top: 3, right: 0 }}
    onClick={onDismiss}
  >
    {closeIcon}
  </Button>
);

export type SidebarProps = {
  positions?: {
    top?: string | number;
    left?: string | number;
  };
  height?: string | number;
  width?: string | number;
  fixed?: boolean;
  isOpen?: boolean;
  onDismiss?: () => void;
  css?: Interpolation<any>;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

interface DialogSubComponents {
  CloseDialog: React.FC<CloseDialogProps>;
}

export const Sidebar: React.FC<SidebarProps> & DialogSubComponents = ({
  isOpen,
  onDismiss,
  height,
  width,
  css,
  children,
  ...props
}) => (
  <Overlay css={css} isOpen={isOpen} onDismiss={onDismiss} {...props}>
    <Element
      as={DialogContent}
      component="SidebarContent"
      className={isOpen ? 'isOpen' : ''}
      css={merge(
        styles.SidebarContent,
        {
          width,
          height
          // transform: isOpen ? 'translateX(0)' : 'translateX(100%)'
        },
        css
      )}
      {...props}
    >
      {children}
    </Element>
  </Overlay>
);

Sidebar.CloseDialog = CloseDialog;
