import React, { useState } from 'react';

import { Element } from '../../primitives';
import { styles } from './dismissal.styles';
import { merge } from '../../utils';

export const Dismissal: React.FC = ({ children }) => {
  const [on, setOn] = useState(true);
  const dismiss = () => setOn(false);

  if (!on) return null;

  return (
    <Element as="div" component="Dismissal" css={styles.Dismissal}>
      <Element as="button" component="CloseDismissal" onClick={dismiss} css={styles.CloseDismissal}>
        x
      </Element>
      {children}
    </Element>
  );
};
