import React, { FieldsetHTMLAttributes, ReactChildren } from 'react';
import PropTypes from 'prop-types';
import { useId } from '@reach/auto-id';

import { styles } from './form.styles';
import { Element } from '../../primitives';
import { Stack } from '../stack';
import { merge } from '../../utils';
import { Interpolation } from '@emotion/react';

export type FormProps = {
  [key: string]: any;
  css?: Interpolation<any>;
} & React.FormHTMLAttributes<HTMLFormElement>;

export type FormHeaderProps = {
  [key: string]: any;
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  css?: Interpolation<any>;
} & React.HtmlHTMLAttributes<HTMLHeadingElement>;

export type FormLabelProps = {
  [key: string]: any;
  css?: Interpolation<any>;
} & React.HtmlHTMLAttributes<HTMLHeadingElement>;

export type FormFieldProps = {
  [key: string]: any;
  css?: Interpolation<any>;
  id?: string;
  label?: string;
  required?: boolean;
} & React.FieldsetHTMLAttributes<HTMLFieldSetElement>;

interface FormSubComponents {
  Header?: React.FC<FormHeaderProps>;
  Label?: React.FC<FormLabelProps>;
  Field?: React.FC<FormFieldProps>;
}

const Form: React.FC<FormProps> & FormSubComponents = React.forwardRef(function Form({ css, ...props }, ref) {
  return (
    <Element ref={ref} as="form" component="Form" css={merge(styles.Form, css)} {...props}>
      {props.children}
    </Element>
  );
});

Form.Header = React.forwardRef(({ css, as = 'h1', ...props }, ref) => (
  <Element ref={ref} as={as} component="FormHeader" css={merge(styles.FormHeader, css)} {...props} />
));

Form.Header.displayName = 'Form.Header';

Form.Label = React.forwardRef(({ css, ...props }, ref) => (
  <Element ref={ref} as="label" component="FormLabel" css={merge(styles.FormLabel, css)} {...props} />
));

Form.Label.displayName = 'Form.Label';

Form.Field = React.forwardRef(({ label, id, required, css, children, ...props }, ref) => {
  const inputId = useId(id);
  return (
    <Element as="fieldset" component="FormField" css={merge(styles.FormField, css)} {...props}>
      {label && (
        <Form.Label htmlFor={inputId}>
          {label} {required ? <span>*</span> : null}{' '}
        </Form.Label>
      )}
      {children}
    </Element>
  );
});

Form.Field.displayName = 'Form.Field';

export { Form };
