import { useState, useEffect } from 'react';
import { useScript } from './useScript';

declare global {
  interface Window {
    grecaptcha: any;
  }
}

const isBrowser = typeof window !== 'undefined' && typeof window.document !== 'undefined';

const injectStyle = (rule: string) => {
  const styleEl = document.createElement('style') as HTMLStyleElement;
  document.head.appendChild(styleEl);

  const styleSheet = styleEl.sheet as CSSStyleSheet;
  if (styleSheet) styleSheet.insertRule(rule, styleSheet.cssRules.length);
};

export interface IUseRecaptcha {
  siteKeyV3: string;
  siteKeyV2?: string;
  recaptchaVersion?: number;
  hideBadge?: boolean;
  htmlElementId?: string;
}

export const useRecaptcha = ({
  siteKeyV3,
  siteKeyV2,
  recaptchaVersion = 3,
  hideBadge = true,
  htmlElementId
}: IUseRecaptcha): [boolean, (props: { action: string }) => Promise<any>, any] => {
  const [isReady, setReady] = useState(false);

  const googleRecaptchaUrl = siteKeyV3 && `https://www.google.com/recaptcha/api.js?render=${siteKeyV3}`;
  const [isRecaptchaScriptLoaded, recaptchaLoadingError] = useScript(googleRecaptchaUrl);

  let captchaId: any;

  useEffect(() => {
    if (isBrowser && hideBadge) injectStyle('.grecaptcha-badge { visibility: hidden; }');
  }, [hideBadge]);

  useEffect(() => {
    if (!window.grecaptcha || !isRecaptchaScriptLoaded) return;

    try {
      window.grecaptcha.ready(() => {
        if (recaptchaVersion === 2) captchaId = window.grecaptcha.render(htmlElementId, { sitekey: siteKeyV2 });
        setReady(true);
      });
    } catch (error) {
      console.error(error);
    }
  }, [isRecaptchaScriptLoaded, recaptchaVersion, isBrowser && window.grecaptcha]);

  const getRecaptchaToken = ({ action }: any): Promise<any> => {
    if (recaptchaVersion === 2) return window.grecaptcha.getResponse(captchaId);
    return window.grecaptcha.execute(siteKeyV3, { action });
  };

  const resetRecapchaToken = () => window.grecaptcha.reset();

  return [isReady, getRecaptchaToken, resetRecapchaToken];
};
