import { useEffect, useReducer, useRef } from 'react';

function useSetState<T>(initialState: T) {
  const [state, setState] = useReducer((state: any, newState: any) => ({ ...state, ...newState }), initialState);

  return [state, setState];
}

export function useSafeSetState<T>(initialState: T) {
  const [state, setState] = useSetState<T>(initialState);
  const mountedRef = useRef(false);

  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const safeSetState = (...args: any) => mountedRef.current && setState(...args);

  return [state, safeSetState];
}
