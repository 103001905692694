// import { Interpolation } from '@emotion/react';
// import theme from '../../themes/base';

// type StylesProps = {
//   [key: string]: InterpolationWithTheme<typeof theme>;
// };

export const styles: any = {
  CarouselWrapper: {
    padding: '68px',
    overflow: 'hidden',
    position: 'relative'
  },
  Carousel: {
    display: 'flex',
    padding: '0 55px',
    ':hover': {
      CarouselItem: {
        // transform: 'translateX(-25%)'
      }
    }
  },
  CarouselItem: {
    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'center',
    // backgroundColor: 'white',
    transition: 'transform 300ms ease 100ms',
    ':hover ~ &': {
      // transform: 'translateX(25%)'
    },
    ':hover': {
      transform: 'scale(1.5) !important',
      zIndex: 9999
    }
  }
};
