import { merge } from '../utils';
/* 
  Tokens: 
  Define your scales first
*/

export interface IThemeSpec {
  [key: string]: string | number | (string | number)[] | React.CSSProperties | IThemeSpec | any;
}

// type ObjectOrArray<T> = T[] | { [K: string]: T | ObjectOrArray<T> };

const tokens: IThemeSpec = {
  name: 'RIQ React UI Base',
  space: {
    0: 0,
    1: '4px',
    2: '8px',
    3: '12px',
    4: '16px',
    5: '20px',
    6: '24px',
    7: '28px',
    8: '32px',
    9: '36px',
    10: '40px',
    11: '44px',
    12: '48px',
    13: '52px',
    14: '56px',
    15: '60px',
    16: '64px'
  },
  radii: [0, '2px', '4px', '8px', '10px', '14px', '18px', '22px', '26px', '30px', '50%'],
  fonts: {
    primary: 'Comfortaa, Noto Sans,sans-serif',
    secondary: 'Gotham, Noto Sans,sans-serif',
    system:
      "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
  },
  fontSizes: {
    0: 0,
    1: '10px',
    2: '12px',
    3: '14px',
    4: '16px',
    5: '20px',
    6: '24px',
    7: '32px',
    8: '48px',
    9: '64px',
    10: '72px'
  },
  fontWeights: {
    thin: 100,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
    black: 900
  },
  lineHeights: {
    compact: '1.2',
    default: '1.6',
    cosy: '2',
    adjustFontHeight: '35px'
  },
  breakpoints: {
    0: '576px',
    1: '768px',
    2: '992px',
    3: '1200px'
  },
  durations: [0, '75ms', '100ms', '150ms', '200ms', '300ms', '500ms', '1000ms'],

  // based on elevation levels
  shadows: {
    0: 'none',
    1: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
    2: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
    3: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
    4: '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
    5: '0 0 0 3px rgba(66, 153, 225, 0.5)'
  },

  colors: {
    white: '#fff',
    reds: {
      100: '#F8E4E4',
      200: '#EFA6A6',
      300: '#E06161',
      400: '#D95252',
      500: '#D12D2D',
      600: '#B41F1F',
      700: '#A20808',
      800: '#821919',
      900: '#5E1717'
    },

    oranges: {
      100: '#ffe0b2',
      200: '#ffcc80',
      300: '#ffb74d',
      400: '#ffa726',
      500: '#f68a53',
      600: '#fb8c00',
      700: '#f57c00',
      800: '#ef6c00',
      900: '#e65100'
    },
    yellows: {
      100: '#FCFCFA',
      200: '#FAECC2',
      300: '#F8E8BA',
      400: '#FAE29F',
      500: '#FFD97E',
      600: '#F4CA64',
      700: '#CAA53D',
      800: '#8C6D1F',
      900: '#5C4813'
    },
    blues: {
      100: '#EFF8FF',
      200: '#B7DBF7',
      300: '#A8D1F2',
      400: '#63A2D8',
      500: '#3793E0',
      600: '#217CC9',
      700: '#2368A2',
      800: '#1A4971',
      900: '#203D54'
    },
    greens: {
      100: '#E3FCEC',
      200: '#A8EEC1',
      300: '#8FF2B2',
      400: '#4BE38C',
      500: '#22D66F',
      600: '#38C172',
      700: '#15763C',
      800: '#13763B',
      900: '#13763B'
    },

    grays: {
      100: '#FEFEFE',
      200: '#EFEFEF',
      300: '#E1E1E1',
      400: '#CDCDCD',
      500: '#BABABA',
      600: '#797979',
      700: '#444444',
      800: '#353535',
      900: '#040707'
    }
  }
};

// recommended: use the same space grid for size
tokens.sizes = { ...tokens.space };

/* 
  Decisions: 
  You can create aliases in scales based on the scale.
*/

tokens.colors.primaryLight = 'greens.100';
tokens.colors.primary = 'greens.800';
tokens.colors.primaryDark = 'greens.900';
tokens.colors.secondary = 'oranges.500';
tokens.colors.outline = 'grays.500';

tokens.colors.text = {
  subtle: 'grays.500',
  subtleDark: 'grays.800',
  heading: 'grays.900',
  body: 'grays.700',
  link: 'blues.500',
  linkHover: 'blues.700'
};

tokens.colors.error = {
  background: 'reds.100',
  border: 'reds.300',
  text: 'reds.700'
};

tokens.colors.App = {
  backgroundColor: 'white',
  color: 'grays.900',
  borderColor: 'grays.200'
};

tokens.colors.cartCounter = {
  empty: 'grays.400',
  nonempty: 'grays.900'
};
tokens.colors.shoppinglist = {
  activeBg: 'primary',
  inactiveBg: 'grays.600',
  activeColor: 'white',
  inactiveColor: 'white',
  podSeparatorColor: 'grays.800'
};

tokens.fontSizes.Heading = {
  page: 8, // reads from tokens.fontSizes.8
  section: 6,
  paragraph: 4
};

/* 
  Component styles:
  You can define styles, sizes and variants here
*/

const components: IThemeSpec = {
  /** Global */
  // @TODO: React-ui global styles doesn't get theme / tokens benifits
  Global: {
    body: {
      fontFamily: 'primary',
      color: 'text.body'
    }
  },

  /** Atoms */
  Button: {
    sizes: { small: 6, medium: 8, large: 10 },
    fontSize: 3,
    borderRadius: 3,
    paddingX: 3,
    border: '1px solid',
    ':hover, :focus, :active': {
      textDecoration: 'none'
    },
    ':focus': {
      outline: 'none',
      // border: '2px solid',
      // borderColor: 'outline'
      boxShadow: '0 0 4px 1px'
    },
    variants: {
      primary: {
        backgroundColor: 'primary',
        color: 'white',
        borderColor: 'primary',
        ':hover:not(:disabled)': {
          backgroundColor: 'white',
          color: 'primary'
        },
        ':active': {
          backgroundColor: 'white',
          color: 'primary'
        },
        '&:disabled': {
          cursor: 'not-allowed'
        }
      },
      primary_noeffect: {
        backgroundColor: 'primary',
        color: 'white',
        borderColor: 'primary',
        '&:disabled': {
          cursor: 'not-allowed'
        }
      },
      secondary: {
        backgroundColor: 'secondary',
        color: 'white',
        borderColor: 'secondary',
        borderRadius: 0
      },
      subtle: {
        backgroundColor: 'grays.600',
        color: 'white',
        borderColor: 'grays.600',
        borderRadius: 0,
        '&:disabled': {
          opacity: 1
        }
      },
      link: {
        color: 'text.link',
        backgroundColor: 'transparent',
        border: 'none',
        background: 'none',
        ':hover, :focus, :active': {
          color: 'text.linkHover'
        }
      },
      ghost: {
        background: 'transparent',
        border: 'none',
        color: 'text.subtle',
        fontSize: 2,
        borderRadius: 0,
        paddingX: 0
      },
      plain: {
        backgroundColor: 'transparent',
        border: 'none',
        ':focus': {
          outline: 'none',
          border: 'none',
          boxShadow: 'none'
        }
      },
      icon: {
        border: 'none'
        // borderRadius: '50%',
        // backgroundColor: 'rgba( 0, 0, 0, 0.05 )',
        // ':focus': {
        //   outline: 'none',
        //   border: 'none',
        //   boxShadow: '0 0 5px px'
        // }
      }
    }
  },
  Text: {
    fontFamily: 'primary',
    // TODO: colors and other properties for common
    variants: {
      default: {
        color: '#000',
        letterSpacing: 'normal'
      },
      heading: {
        color: 'primary',
        letterSpacing: 'normal',
        fontWeight: 'bold'
      },
      body: {
        color: 'grays.900',
        letterSpacing: 'normal'
      },
      subtle: {
        color: 'text.subtle',
        letterSpacing: 'normal'
      },
      subtleDark: {
        color: 'grays.700',
        letterSpacing: 'normal'
      },
      danger: {
        color: 'reds.600',
        letterSpacing: 'normal'
      }
    }
  },
  Heading: {
    /** fontSizes for heading are defined in tokens.fontSizes.Heading */
    color: 'text.heading'
  },
  Link: {
    color: 'text.link',
    textDecoration: 'none',
    ':hover': {
      color: 'text.linkHover'
    },
    variants: {
      default: { color: 'inherit' },
      primary: { color: 'primary' },
      subtle: {
        color: 'grey',
        ':hover': {
          color: 'text.linkHover'
        }
      },
      heading: { color: 'text.heading' },
      body: { color: 'text.body' },
      subtleDark: { color: 'text.subtleDark' },
      danger: { color: 'error.text' }
    }
  },
  Input: {
    // recommended: match sizes of input and buttons so
    // that they go well together in forms next to other
    sizes: { small: 6, medium: 8, large: 10 },
    fontFamily: 'primary',
    fontSize: 3,
    borderRadius: 3,
    paddingX: 2,
    backgroundColor: 'grays.100',
    border: '1px solid transparent',
    borderColor: 'primary',
    // borderColor: 'grays.400',
    color: 'text.body',
    '::placeholder': {
      color: 'text.subtle'
    },
    ':hover:not(:disabled)': {
      borderColor: 'primary'
    },
    ':focus:not(:disabled)': {
      outline: 'none',
      borderColor: 'primary'
    },
    '&[aria-invalid="true"]': {
      backgroundColor: 'error.background',
      borderColor: 'error.border'
    },
    '&[aria-invalid="true"]:focus:not(:disabled)': {
      backgroundColor: 'error.background',
      borderColor: 'error.border'
    }
  },
  InputGroup: {
    // recommended: match sizes of input and buttons so
    // that they go well together in forms next to other
    sizes: { small: 6, medium: 8, large: 10 },
    fontFamily: 'primary',
    fontSize: 3
    // '> *': {
    //   paddingX: 2
    // }
  },
  InputAddon: {
    padding: '0 15px',
    border: '1px solid',
    '&:first-child': { borderRadius: '8px 0 0 8px' },
    '&:last-child': { borderRadius: '0 8px 8px 0' }
  },
  InputField: {
    paddingX: 2,
    color: 'text.body',
    '::placeholder': {
      color: 'text.subtle'
    },
    ':hover:not(:disabled)': {
      borderColor: 'primary'
    },
    ':focus:not(:disabled)': {
      outline: 'none',
      borderColor: 'primary'
    },
    '&[aria-invalid="true"]': {
      backgroundColor: 'error.background',
      borderColor: 'error.border'
    }
  },
  Checkbox: {
    border: '1px solid'
  },

  /** Molecules */
  Card: {
    fontFamily: 'secondary',
    backgroundColor: 'white',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'grays.400',
    borderRadius: 0,
    boxShadow: 0,
    variants: {
      list: {
        borderWidth: '0 0 1px 0',
        paddingY: 4
      }
    }
  },
  Loader: {
    sizes: { small: 4, medium: 6, large: 8 },
    borderColor: 'primaryLight',
    borderLeftColor: 'primary',
    listStyle: 'disc'
  },
  List: {
    // borderBottom: '5px solid',
    // borderColor: 'secondary'
  },
  ListItem: {
    padding: 2,
    // borderBottom: '1px solid',
    // borderColor: 'grays.200'
    ':hover, :active, :focus': {
      backgroundColor: 'grays.200'
    },
    Link: {
      ':hover': {
        color: 'primary'
      }
    }
  },
  MenuList: {
    backgroundColor: 'white',
    color: 'text.body',
    fontSize: 3,
    borderRadius: 2,
    marginTop: 1,
    boxShadow: 3
  },
  MenuItem: {
    paddingY: 2,
    paddingX: 3,
    '&[data-selected]': {
      backgroundColor: 'blue',
      color: 'white'
    }
  },
  DialogContent: {
    backgroundColor: 'white',
    borderRadius: 2,
    boxShadow: 2
  },
  DialogOverlay: {
    backgroundColor: (theme) => theme.colors.grays[800] + 'e9'
  },
  SidebarContent: {
    backgroundColor: 'white',
    borderRadius: 0,
    boxShadow: 2
  },
  Adornment: {
    background: 'primary'
  },
  IconButton: {
    backgroundColor: 'rgba(0, 0, 0, .02)',
    ':focus': {
      outline: 'none',
      borderColor: 'rgba(0, 0, 0, .3)',
      backgroundColor: 'none'
    },
    ':hover, :active': {
      backgroundColor: 'rgba(0, 0, 0, .1)'
    }
  }
};

const theme: IThemeSpec = { tokens, components };

export const createTheme = ({ mode = 'light', tokens: customToken, components: customComponents }) => {
  const themeTokens = merge(tokens, customToken);
  const themeComponents = merge(components, customComponents);

  return { tokens: themeTokens, components: themeComponents };
};

export default theme;
export { tokens, components };
