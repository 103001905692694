import React from 'react';

export const SearchIcon = ({ width = '19.865', height = '19.366', fill = 'currentColor' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 19.865 19.366">
    <g transform="translate(0 0)">
      <path
        fill={fill}
        d="M18.616,19.366A1.242,1.242,0,0,1,17.733,19l-4.315-3.811A8.415,8.415,0,0,1,0,8.418,8.416,8.416,0,0,1,14.367,2.465a8.419,8.419,0,0,1,.818,10.957L19.5,17.233a1.25,1.25,0,0,1-.883,2.133ZM8.414,1.816A6.6,6.6,0,0,0,3.747,13.087a6.6,6.6,0,0,0,9.119.208l.221-.2.2-.222A6.6,6.6,0,0,0,8.414,1.816Z"
        transform="translate(0 0)"
      />
    </g>
  </svg>
);
