import React from 'react';

import { styles } from './pod.styles';
import { merge } from '../../utils';
import { Stack } from '../stack';
import { Text } from '../text';
import { Image } from '../img';
import { IPodProps } from './types';
import { Element } from '../../primitives';

export const ShoppingListPod: React.FC<IPodProps> = function ListPod({ data, ...props }, ref) {
  return (
    <Element marginY={1} css={merge(styles.root, { borderColor: 'shoppinglist.podSeparatorColor' })} {...props}>
      <Stack direction={'horizontal'} gap="2" align="flex-start">
        {data.brandName && (
          <Image
            css={merge(styles.img, { height: '60px', maxWidth: '60px', padding: '1px' })}
            style={{ width: '45px', height: '45px' }}
            src={data.largeImage || data.offerImage}
            alt={data.brandName}
          />
        )}
        <Stack direction="vertical" gap={1} css={{ flex: 1, lineHeight: 'compact', maxWidth: '215px' }}>
          {data.offerSummary && (
            <Text size={3} weight="bold" css={{ textTransform: 'uppercase' }}>
              {data.offerSummary}
            </Text>
          )}
          {data.offerDescription && (
            <Text variant="subtle" size={2} css={styles.desc}>
              {data.offerDescription}
            </Text>
          )}
          <Text size={2}>Exp. {data.offerExpiryDateFormatted}</Text>
        </Stack>
      </Stack>
    </Element>
  );
};
