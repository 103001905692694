import React from 'react';
import { Element } from '../../primitives';

type AlphaNum = number | string;

interface ISvgProps extends React.SVGAttributes<SVGElement> {
  [key: string]: any;
  size?: AlphaNum | AlphaNum[];
  margin?: AlphaNum | AlphaNum[];
  marginX?: AlphaNum | AlphaNum[];
  marginY?: AlphaNum | AlphaNum[];
  marginTop?: AlphaNum | AlphaNum[];
  marginRight?: AlphaNum | AlphaNum[];
  marginBottom?: AlphaNum | AlphaNum[];
  marginLeft?: AlphaNum | AlphaNum[];
  ref?: React.Ref<unknown>;
}

export const Svg: React.FC<ISvgProps> = React.forwardRef(function Svg({ size = 24, ...props }, ref) {
  return (
    <Element
      ref={ref}
      as="svg"
      xmlns="http://www.w3.org/2000/svg"
      width={size + ''}
      height={size + ''}
      viewBox="0 0 24 24"
      fill="currentcolor"
      {...props}
    />
  );
});
