import React from 'react';
import { Element } from '../../primitives';
import { styles } from './list.styles';
import { merge } from '../../utils';
import { Text } from '../text';

type AlphaNum = number | string;

interface IListProps extends React.HtmlHTMLAttributes<HTMLUListElement> {
  [key: string]: any;
  margin?: AlphaNum | AlphaNum[];
  marginX?: AlphaNum | AlphaNum[];
  marginY?: AlphaNum | AlphaNum[];
  marginTop?: AlphaNum | AlphaNum[];
  marginRight?: AlphaNum | AlphaNum[];
  marginBottom?: AlphaNum | AlphaNum[];
  marginLeft?: AlphaNum | AlphaNum[];
  as?: string | React.ElementType | keyof JSX.IntrinsicElements;
  component?: string;
  ref?: React.Ref<unknown>;
  css?: any;
}

export const List: React.FC<IListProps> = React.forwardRef(function List({ css, ...props }, ref) {
  return <Element ref={ref} as="ul" component="List" css={merge(styles.List, css)} {...props} />;
});

interface IListItemProps extends React.HtmlHTMLAttributes<HTMLLIElement> {
  [key: string]: any;
  margin?: AlphaNum | AlphaNum[];
  marginX?: AlphaNum | AlphaNum[];
  marginY?: AlphaNum | AlphaNum[];
  marginTop?: AlphaNum | AlphaNum[];
  marginRight?: AlphaNum | AlphaNum[];
  marginBottom?: AlphaNum | AlphaNum[];
  marginLeft?: AlphaNum | AlphaNum[];
  as?: string | React.ElementType | keyof JSX.IntrinsicElements;
  component?: string;
  ref?: React.Ref<unknown>;
  css?: any;
}

export const ListItem: React.FC<IListItemProps> = React.forwardRef(function ListItem({ css, ...props }, ref) {
  return <Text ref={ref} as="li" component="ListItem" css={merge(styles.ListItem, css)} {...props} />;
});

List.defaultProps = {};
