import React, { ButtonHTMLAttributes } from 'react';
import { useSafeSetState } from '@quotient/riq-react-hooks';

import { styles } from './pod.styles';
import { Stack } from '../stack';
import { Card } from '../card';
import { Button } from '../button';
import { Text } from '../text';
import { SeperatorText } from '../seperatorText';
import { Image } from '../img';

type AlphaNum = number | string;

interface ICardData {
  offerId: string;
  offerCode: string;
  omsCid: number;
  offerDescription: string;
  offerExpiryDateFormatted: string;
  offerActiveDate: string;
  brandName: string;
  offerDisclaimer: string;
  offerSummary: string;
  offerShutoffDate: string;
  offerRewards: {
    categoryName: string;
    rewardQuantity: string;
  };
  hierarchy: {
    categoryLevel1: string;
  };
  offerConditions: {
    minQty: number;
    redemptionFrequency: string;
    redemptionLimit: string;
    offerType: string;
  };
  offerSource: string;
  offerSourceList: string[];
  discountType: string;
  offerValue: string;
  offerType: string;
  hasExtendedRequirements: boolean;
  isManufactory: boolean;
  isExclusiveOffer: boolean;
  isEmployeeOffer: boolean;
  isContinuity: boolean;
  isDailyDealOffer: boolean;
  fogFeature: boolean;
  redeemed: boolean;
  activated: boolean;
  offerExpiryDate: string;
  redemptionDate: string;
  offerImage: string;
  smallImage: string;
  mediumImage: string;
  largeImage: string;
}

interface IGridPodProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  data: Partial<ICardData>;
  margin?: AlphaNum | AlphaNum[];
  marginX?: AlphaNum | AlphaNum[];
  marginY?: AlphaNum | AlphaNum[];
  marginTop?: AlphaNum | AlphaNum[];
  marginRight?: AlphaNum | AlphaNum[];
  marginBottom?: AlphaNum | AlphaNum[];
  marginLeft?: AlphaNum | AlphaNum[];
  ref?: React.Ref<unknown>;
}

export const GridPod: React.FC<IGridPodProps> = ({ data, ...props }) => {
  const [modalState, setModalState] = useSafeSetState({ visible: false });
  const toggelModal = (e: React.MouseEvent<HTMLButtonElement>) => setModalState({ visible: !modalState.visible });

  return (
    <Card {...props}>
      {/* <div tabIndex={0} className="action">
          <span className="acttionText">Add to List</span>
          <span className="actionIcon">+</span>
        </div> */}
      <Stack direction="vertical" gap={2} align="center">
        {data.largeImage && data.brandName && <Image css={styles.img} src={data.largeImage} alt={data.brandName} />}
        {data.offerSummary && <SeperatorText size={6}>{data.offerSummary}</SeperatorText>}

        <Stack direction="vertical" gap={2} css={{ paddingX: 4 }}>
          <Text variant="body" size={3}>
            {data.brandName}
          </Text>
          <Text variant="subtle" size={1} css={styles.desc}>
            {data.offerDescription}
          </Text>
          <Stack align="center" justify="space-between">
            <Button variant="ghost" size="small" onClick={toggelModal}>
              Details <Text size={1}>▼</Text>
            </Button>
            <Text variant="body" size={2}>
              {data.offerExpiryDateFormatted}
            </Text>
          </Stack>
        </Stack>
      </Stack>
    </Card>
  );
};
