import React from 'react';
import { Stack, Text, Link } from '@quotient/riq-react-ui';

export default function HelpFaqLink({ css, ...props }: any) {
  return (
    <Stack
      align="center"
      justify={['center', 'flex-start']}
      css={{
        paddingY: 4,
        ...css
      }}
      {...props}
    >
      <Text weight="500">
        Need additional help? Check out our{' '}
        <Link
          target="_blank"
          href="https://quotient.my.site.com/CouponsSupportCenter/s/topic/0TO3g000000PE0DGAW/digital-rebates"
        >
          FAQ
        </Link>
      </Text>
    </Stack>
  );
}
