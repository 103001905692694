import { createTheme } from '@quotient/riq-react-ui/theme';

const colors = {
  blues: {
    100: '#A6D9FF',
    200: '#8ACBFF',
    300: '#37A8FF',
    400: '#1C92FF',
    500: '#017AFF',
    600: '#0071EB',
    700: '#004ABF',
    800: '#00369F',
    900: '#002480'
  },
  grays: {
    100: '#FEFEFE',
    200: '#EFEFEF',
    300: '#E1E1E1',
    400: '#CDCDCD',
    500: '#BABABA',
    600: '#69696B',
    700: '#444444',
    800: '#353535',
    900: '#040707'
  },
  reds: {
    600: '#E21220'
  },
  primaryLight: 'blues.100',
  primary: 'blues.500',
  primaryDark: 'blues.900',
  secondary: 'grays.600',
  text: {
    subtle: 'grays.600',
    subtleDark: 'grays.800',
    heading: 'grays.900',
    body: 'grays.700',
    link: 'blues.600',
    linkHover: 'blues.700'
  },
  error: {
    text: 'reds.600',
    border: 'reds.600'
  }
};

const { tokens, components } = createTheme({
  mode: 'light',
  tokens: {
    colors,
    fontSizes: {
      0: 0,
      1: '10px',
      2: '12px',
      3: '14px',
      4: '16px',
      5: '18px',
      6: '20px',
      7: '22px',
      8: '24px',
      9: '28px',
      10: '32px',
      Heading: {
        page: 7, // reads from tokens.fontSizes.8
        section: 5,
        paragraph: 3
      }
    },
    fonts: {
      primary: 'Roboto, Helvetica Neue, sans-serif'
    }
  },
  components: {
    Global: {
      html: {
        height: '100%'
      },
      body: {
        height: '100%',
        margin: 0,
        fontSize: 4
      },
      'body > div:first-of-type': {
        height: '100%'
      }
    },
    Text: {
      fontSize: [3, 4],
      variants: {
        default: {
          color: 'grays.900'
        }
      }
    },
    Button: {
      sizes: { small: 8, medium: 12, large: 14 },
      fontSize: [4, 6],
      borderRadius: 2,
      ':disabled': {
        backgroundColor: 'grays.600'
      }
    },
    Input: {
      sizes: { small: 8, medium: 12, large: 14 },
      borderRadius: 2,
      fontSize: 4,
      paddingX: 3,
      borderWidth: '2px',
      borderColor: 'grays.500',
      '&[aria-invalid="true"]': {
        backgroundColor: 'initial',
        borderColor: 'error.border'
      },
      '&[aria-invalid="true"]:focus:not(:disabled)': {
        backgroundColor: 'initial',
        borderColor: 'error.border'
      }
    },
    FormLabel: {
      color: 'grays.600',
      paddingY: 2
    }
  }
});

export default { tokens, components };
