import React, { useState, useEffect } from 'react';
import { Element } from '../../primitives';
import { styles } from './searchInput.styles';
import { merge } from '../../utils';
import { SearchIcon } from './../../icons/SearchIcon';
import { Stack } from '../stack';
import { Text } from '../text';
import { Button } from '../button';

type AlphaNum = number | string;

interface ISearchInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  [key: string]: any;
  invalid?: boolean;
  css?: any;
  type?: any;
  size?: any;
  margin?: AlphaNum | AlphaNum[];
  marginX?: AlphaNum | AlphaNum[];
  marginY?: AlphaNum | AlphaNum[];
  marginTop?: AlphaNum | AlphaNum[];
  marginRight?: AlphaNum | AlphaNum[];
  marginBottom?: AlphaNum | AlphaNum[];
  marginLeft?: AlphaNum | AlphaNum[];
  as?: string | React.ElementType | keyof JSX.IntrinsicElements;
  component?: string;
  ref?: React.Ref<unknown>;
  searchContent?: any;
  buttonTextColor?: string;
}

/** Description of an input */
export const SearchInput: React.FC<ISearchInputProps> = React.forwardRef(function SearchInputInner(
  { invalid, css, value, searchContent, buttonTextColor, ...props },
  ref
) {
  const [showSearchBox, setShowSearchBox] = React.useState(false);

  const toggleSearchBox = () => {
    setShowSearchBox(!showSearchBox);
    if (!showSearchBox && typeof value === 'string' && value?.length > 0) {
      props.updateSearchContent(true);
    } else {
      props.updateSearchContent(false);
    }
  };

  const clearSearchBox = () => {
    props.updateValue('');
  };

  return (
    <Stack css={css}>
      <Element
        ref={ref}
        as="input"
        component="SearchInput"
        aria-invalid={invalid}
        css={merge(styles.searchInput, { display: showSearchBox ? 'flex' : 'none' })}
        value={value}
        {...props}
      />
      <Element
        css={{
          display: searchContent ? 'inline-flex' : 'none',
          alignItems: 'center',
          position: 'absolute',
          top: '11px',
          right: '122px',
          border: 'none',
          background: 'none'
        }}
        as="button"
        onClick={clearSearchBox}
      >
        x
      </Element>
      <Element
        as="button"
        css={{
          display: searchContent ? 'inline-flex' : 'none',
          border: 'none',
          background: 'primary',
          position: 'absolute',
          right: '66px',
          height: '44px',
          width: '44px',
          color: buttonTextColor,
          alignItems: 'center',
          justifyContent: 'center'
        }}
        onClick={props.onSubmit}
      >
        go
      </Element>
      <Element
        css={{
          height: '44px',
          width: '44px',
          background: showSearchBox ? 'white' : 'primary',
          display: showSearchBox ? 'none' : 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
        onClick={toggleSearchBox}
      >
        <SearchIcon fill={buttonTextColor} height="30px" width="30px" />
      </Element>
      <Element
        css={{
          height: '44px',
          width: '68px',
          background: showSearchBox ? 'white' : 'primary',
          display: showSearchBox ? 'flex' : 'none',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '12px 10px'
        }}
        onClick={toggleSearchBox}
      >
        <Text weight="900" color="secondary">
          Cancel
        </Text>
      </Element>
    </Stack>
  );
});

SearchInput.defaultProps = {
  type: 'search'
};
