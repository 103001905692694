import React from 'react';

import { styles } from './pod.styles';
import { merge } from '../../utils';
import { Stack } from '../stack';
import { Card } from '../card';
import { Text } from '../text';
import { Image } from '../img';
import { AddRoundIcon } from '../../icons/AddRound';
import { IconButton } from '../button';
import { IPodProps } from './types';

// const ClipButton = () => {
//   return (
//     <div tabIndex={0} className="action">
//       <span className="acttionText">Add to List</span>
//       <AddRoundIcon />
//     </div>
//   );
// };

export const ListPod: React.FC<IPodProps> = function ListPod(
  { data, showDesc = true, showPopuponBody = false, onClip, clipButton, clippedButton, ...props },
  ref
) {
  const submitAction = (e: React.MouseEvent) => e.preventDefault && onClip && onClip(data);
  const isDisabled = data.offerType === 'activated' || data.activated;

  return (
    <Card marginY={2} variant="list" css={merge(styles.root)} {...props}>
      <Stack direction={['horizontal']} align="center">
        {data.brandName && (
          <Image
            css={merge(styles.img, { alignSelf: 'baseline', paddingTop: ['0', '1rem'] })}
            src={data.largeImage || data.offerImage}
            alt={data.brandName}
          />
        )}
        <Stack direction="vertical" gap={1} css={{ flex: 1 }}>
          {data.offerSummary && (
            <Text size={6} weight="bold">
              {data.offerSummary}
            </Text>
          )}
          {showDesc && (
            <Text variant="body" size={5}>
              {data.brandName}
            </Text>
          )}
          {data.offerDescription && (
            <Text variant="body" size={3} css={styles.desc}>
              {data.offerDescription}
            </Text>
          )}
          <Text variant="body" weight="bold" size={3}>
            Exp. {data.offerExpiryDateFormatted}
          </Text>
        </Stack>
        <Stack css={{ width: 'auto', marginLeft: ['10px', 0] }}>
          {(isDisabled ? clippedButton : clipButton) || (
            <IconButton
              variant="ghost"
              css={{ color: isDisabled ? 'grays.500' : 'primary' }}
              onClick={submitAction}
              disabled={isDisabled}
            >
              <AddRoundIcon fill="black" />
            </IconButton>
          )}
        </Stack>
      </Stack>
    </Card>
  );
};
