import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { Stack } from '@quotient/riq-react-ui';
import Footer from './Footer';

const Page: React.FC<RouteComponentProps> = ({ children }) => {
  return (
    <Stack
      component="Page"
      css={{
        maxWidth: ['none', '1170px'],
        paddingX: [5, 6],
        overflow: 'hidden',
        overflowY: 'auto',
        marginTop: [5, '100px'],
        '> div': { height: '100%' }
      }}
      marginX="auto"
      direction="vertical"
    >
      {children}
    </Stack>
  );
};

export default Page;
