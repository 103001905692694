// TODO: Create a global custom event dispatcher in packages/riq-helpers
declare global {
  interface Window {
    dataLayer: any;
  }
}

const eventsList = [
  'couponsinc:digitalbricks:viewoffer',
  'couponsinc:digitalbricks:agegate',
  'couponsinc:digitalbricks:submitphone',
  'couponsinc:digitalbricks:otp',
  'couponsinc:digitalbricks:shortURLsent'
];

export const pushEventsToDataLayer = function () {
  const isClient = typeof window !== 'undefined';

  if (isClient) window.dataLayer = window.dataLayer || [];
};

export const dispatchLoggingEvent = function (event: string, detail: any) {
  const isClient = typeof window !== 'undefined';

  if (isClient) {
    document.dispatchEvent(new CustomEvent(event, { detail: detail }));

    // add event data to dataLayer only if present in eventList
    if (eventsList.indexOf(event) > -1 && window.dataLayer) window.dataLayer.push(detail);
  }
};
