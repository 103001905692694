import React from 'react';
import { Text } from '../text';
import { styles } from './seperatorText.styles';

type AlphaNum = number | string;

interface ISeperatorTextProps extends React.HtmlHTMLAttributes<HTMLSpanElement> {
  [key: string]: any;
  variant?: 'default' | 'heading' | 'body' | 'subtle' | 'subtleDark' | 'danger';
  size?: number;
  color?: string;
  align?: 'left' | 'right' | 'center' | 'justify' | 'inherit' | 'initial';
  weight?: string;
  block?: boolean;
  maxWidth?: string;
  margin?: AlphaNum | AlphaNum[];
  marginX?: AlphaNum | AlphaNum[];
  marginY?: AlphaNum | AlphaNum[];
  marginTop?: AlphaNum | AlphaNum[];
  marginRight?: AlphaNum | AlphaNum[];
  marginBottom?: AlphaNum | AlphaNum[];
  marginLeft?: AlphaNum | AlphaNum[];
  as?: string | React.ElementType | keyof JSX.IntrinsicElements;
  component?: string;
  ref?: React.Ref<unknown>;
  css?: any;
}

export const SeperatorText: React.FC<ISeperatorTextProps> = React.forwardRef(function SeperatorText(props, ref) {
  return <Text ref={ref} css={styles.SeperatorText} {...props} fullWidth />;
});
