import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { Stack, Heading, List, ListItem, Image } from '@quotient/riq-react-ui';
import HelpFaqLink from '../../components/HelpFaqLink';

const styles = {
  listItem: { fontSize: [3, 4], fontWeight: [400, 500], display: 'list-item' },
  errorImg: { flex: 0.4, alignItems: 'center', width: ['125px', '240px'], height: ['156px', '300px'] }
};

export default function NotFound(props: RouteComponentProps) {
  return (
    <Stack direction={['vertical', 'horizontal']} align="center">
      <Image
        marginY={[2, 4]}
        css={styles.errorImg}
        src="https://cdn.cpnscdn.com/static.coupons.com/Sites/img/riq/digital-bricks/sms-rebate-offer-not-found.svg"
        alt="sms rebate offer not found"
      />

      <Stack direction="vertical" gap={[3, 4]} css={{ flex: 0.6 }}>
        <Heading css={{ fontSize: [7, 8] }}>
          Sorry, looks like this offer is not available due to one of the following reasons:
        </Heading>
        <List css={{ paddingInlineStart: '16px' }}>
          <ListItem css={styles.listItem}>offer is not available in your area</ListItem>
          <ListItem css={styles.listItem}>offer has been redeemed</ListItem>
          <ListItem css={styles.listItem}>offer is invalid or reached its limit</ListItem>
          <ListItem css={styles.listItem}>offer/receipt has expired</ListItem>
        </List>
        <HelpFaqLink />
      </Stack>
    </Stack>
  );
}
