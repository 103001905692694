import React from 'react';

import { Stack } from '../stack';
import { Button } from '../button';
import { Text } from '../text';
import { Input } from '../input';
import { Checkbox } from '../checkbox';
import { DeleteIcon } from '../../icons/DeleteIcon';
import { Element } from '../../primitives';
// import { useOnClickOutside } from '@quotient/riq-react-hooks';

function _EditableItemPod({ data, onUpdate, onDelete }: any) {
  const [editable, setEditable] = React.useState(false);
  const [editableItemNumber, setEditableItemNumber] = React.useState(false);
  const [itemName, setItemName] = React.useState(data?.itemName || '');
  const [itemQuantity, setItemQuantity] = React.useState(data?.itemQuantity || '');
  const itemNameRef = React.useRef(null);
  const itemQtyRef = React.useRef(null);

  const toggleTextEditable = () => setEditable(!editable);
  const toggleNumberEditable = () => setEditableItemNumber(!editableItemNumber);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target;
    const value = target.name === 'itemSelected' ? target.checked : target.value;
    target.name === 'itemName' && setItemName(value);
    target.name === 'itemQuantity' && setItemQuantity(parseInt(value as string) || '');
  };

  // @TODO: Extract this inline editable UI functionality into the input component

  React.useEffect(() => {
    if (editableItemNumber && itemQtyRef && itemQtyRef.current) {
      itemQtyRef.current.focus();
    } else if (editable && itemNameRef && itemNameRef.current) {
      itemNameRef.current.focus();
    }
  }, [itemNameRef, itemQtyRef, editable, editableItemNumber]);

  const onKeyDown = (e, blur = false) => {
    const enterKey = e.key === 'Enter' || e.keyCode === 13;
    const tabKey = e.key === 'Tab' || e.keyCode === 13;
    if (e.key === 'Escape' || e.keyCode === 27) {
      // reset item
      itemName !== data?.itemName && setItemName(data?.itemName || '');
      itemQuantity !== data?.itemQuantity && setItemQuantity(data?.itemQuantity || 1);
      e.currentTarget == itemQtyRef.current ? toggleNumberEditable() : toggleTextEditable();
    } else if (enterKey || tabKey || blur) {
      const item = { ...data, itemName, itemQuantity };
      (editable || editableItemNumber) && onUpdate(item);
      e.currentTarget == itemQtyRef.current ? toggleNumberEditable() : toggleTextEditable();
    }
  };

  const onTextKeyDown = (e: React.KeyboardEvent) => {
    const enterKey = e.key === 'Enter' || e.keyCode === 13;
    if (enterKey) {
      e.currentTarget == itemQtyRef.current ? toggleNumberEditable() : toggleTextEditable();
    }
  };

  const handleCrossedOutItem = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;

    const item = {
      ...data,
      customAttributes: {
        ...data.customAttributes,
        crossout: checked ? 'crossout' : '',
        oldCrossOut: data.customAttributes.crossout
      }
    };
    onUpdate(item);
  };

  let crossout = data?.customAttributes?.crossout;
  if (typeof data?.customAttributes?.tempCrossout !== 'undefined') {
    crossout = data.customAttributes.tempCrossout;
  }

  const styles = {
    root: {
      position: 'relative' as const,
      padding: '4px 0px'
    },
    qtyText: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '40px',
      width: '36px',
      paddingX: 2,
      lineHeight: 1,
      border: '1px solid',
      borderColor: 'grays.300',
      borderRadius: 3
    },
    qtyInput: {
      width: '36px',
      textAlign: 'center' as const
    },
    nameText: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '40px',
      paddingX: 2,
      lineHeight: 1,
      border: '1px solid transparent'
    },
    nameInput: {}
  };

  return (
    <Element css={styles.root}>
      <Stack
        key={data.itemId}
        css={{
          width: '100%',
          padding: 2,
          borderBottom: '1px solid',
          borderColor: 'grays.300'
        }}
      >
        <Element
          css={{
            display: crossout ? 'inline-block' : 'none',
            zIndex: 10,
            position: 'absolute',
            top: '49%',
            width: '80%',
            border: '0',
            borderTop: '1px solid #000',
            marginLeft: '5%'
          }}
        ></Element>
        <Checkbox name="itemSelected" label="" defaultChecked={crossout} onChange={handleCrossedOutItem} />
        {editableItemNumber ? (
          <Stack align="center" css={{ paddingX: 4 }}>
            <Input
              ref={itemQtyRef}
              size="large"
              css={styles.qtyInput}
              maxLength={3}
              name="itemQuantity"
              value={itemQuantity}
              onChange={handleChange}
              onKeyDown={onKeyDown}
              onBlur={(e) => {
                setEditableItemNumber(false);
                onKeyDown(e, true);
              }}
            />
          </Stack>
        ) : (
          <Stack
            align="center"
            css={{ paddingX: 4 }}
            onClick={() => {
              setEditableItemNumber(true);
            }}
          >
            <Text css={styles.qtyText} tabIndex={0} onKeyDown={onTextKeyDown}>
              {data.itemQuantity}
            </Text>
          </Stack>
        )}

        {editable ? (
          <Stack align="center" css={{ flex: 1, paddingX: 4 }}>
            <Input
              ref={itemNameRef}
              size="large"
              name="itemName"
              value={itemName}
              onChange={handleChange}
              onKeyDown={onKeyDown}
              onBlur={(e) => {
                setEditable(false);
                onKeyDown(e, true);
              }}
            />
          </Stack>
        ) : (
          <Stack
            align="center"
            css={{ flex: 1, paddingX: 4 }}
            onClick={() => {
              setEditable(true);
            }}
          >
            <Text css={styles.nameText} tabIndex={0} onKeyDown={onTextKeyDown}>
              {data.itemName}
            </Text>
          </Stack>
        )}
        <Button variant="icon" css={{ backgroundColor: 'white' }} onClick={onDelete(data?.itemId)}>
          <DeleteIcon />
        </Button>
      </Stack>
    </Element>
  );
}

export const EditableItemPod = React.memo(_EditableItemPod);
