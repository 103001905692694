import React from 'react';
import { Element } from '../../primitives';
import { styles } from './card.styles';
import { merge } from '../../utils';

type AlphaNum = number | string;

interface ICardProps extends React.HtmlHTMLAttributes<HTMLElement> {
  [key: string]: any;
  variant?: 'default' | 'list';
  margin?: AlphaNum | AlphaNum[];
  marginX?: AlphaNum | AlphaNum[];
  marginY?: AlphaNum | AlphaNum[];
  marginTop?: AlphaNum | AlphaNum[];
  marginRight?: AlphaNum | AlphaNum[];
  marginBottom?: AlphaNum | AlphaNum[];
  marginLeft?: AlphaNum | AlphaNum[];
  as?: string | React.ElementType | keyof JSX.IntrinsicElements;
  component?: string;
  ref?: React.Ref<unknown>;
}

export const Card: React.FC<ICardProps> = React.forwardRef(function Card({ css, ...props }, ref) {
  return <Element ref={ref} as="div" component="Card" css={merge(styles.Card, css)} {...props} />;
});
