import React from 'react';
import { styles } from './link.styles';
import { Text } from '../text';
import { merge } from '../../utils';

type AlphaNum = number | string;

interface ILinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  [key: string]: any;
  variant?: 'default' | 'primary' | 'heading' | 'body' | 'subtle' | 'subtleDark' | 'danger' | any;
  size?: AlphaNum | AlphaNum[];
  color?: string;
  align?: 'left' | 'right' | 'center' | 'justify' | 'inherit' | 'initial';
  weight?: string;
  block?: boolean;
  maxWidth?: string;
  fullWidth?: boolean;
  margin?: AlphaNum | AlphaNum[];
  marginX?: AlphaNum | AlphaNum[];
  marginY?: AlphaNum | AlphaNum[];
  marginTop?: AlphaNum | AlphaNum[];
  marginRight?: AlphaNum | AlphaNum[];
  marginBottom?: AlphaNum | AlphaNum[];
  marginLeft?: AlphaNum | AlphaNum[];
  as?: string | React.ElementType | keyof JSX.IntrinsicElements;
  component?: string;
  ref?: React.Ref<unknown>;
}

export const Link: React.FC<ILinkProps> = React.forwardRef(function Link({ css, ...props }, ref) {
  return <Text ref={ref} as="a" component="Link" css={merge(styles.Link, css)} {...props} />;
});
